import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  return (
    <>
      <SEO title="Contact" />
      <Layout>
        <h1 className="page-heading">Let's Talk!</h1>
        <p>
          Say hello or tell me how I can help you with your next web project.
          I'm waiting to hear from ya{" "}
          <span role="img" aria-label="cat smiliing emoji">
            😸
          </span>
        </p>
        <section className="social-links">
          <ul>
            <li>
              <a href="mailto:briana.dacoscos@gmail.com" target="_blank">
                <i class="far fa-envelope"></i>Email me
              </a>
            </li>
            <li>
              <a href="https://github.com/bdacoscos" target="_blank">
                <i class="fab fa-github"></i>Check out my code
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/bkdacoscos/" target="_blank">
                <i class="fab fa-linkedin-in"></i>Let's link up
              </a>
            </li>
            <li>
              <a href="https://twitter.com/bdasnachos" target="_blank">
                <i class="fab fa-twitter"></i>Tweet, tweet
              </a>
            </li>
          </ul>
        </section>
      </Layout>
    </>
  )
}

export default ContactPage
